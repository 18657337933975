import React, { useContext } from 'react';
import './Spinner.css';
import { spinnerContext } from '../../store/spinner';
import { Box } from '@mui/material';

export default function Spinner() {
  const [showSpinner] = useContext(spinnerContext);
  return (
    <>
      {showSpinner.show && (
        <Box className="spinner">
          <Box className="backdrop" />
          <Box className="spinner-graphic">
            <svg
              id="spinner"
              data-name="spinner"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 182.32 182.32"
            >
              <g className="plane-wrapper">
                <path
                  className="cls-1"
                  d="M91.15,0a91.16,91.16,0,1,0,91.16,91.16h0A91.15,91.15,0,0,0,91.17,0Zm0,119.8a28.64,28.64,0,1,1,28.64-28.64h0A28.63,28.63,0,0,1,91.17,119.8Z"
                  transform="translate(0.01)"
                />
                <path
                  className="cls-4"
                  d="M111.13,50.58h0m46.14,20.11-5.1,3.08a1.44,1.44,0,0,1-1.23.14l-5.63-1.8a5.51,5.51,0,0,0,.3,1c.79.6,3.47,2.48,4.47,3.18a1.48,1.48,0,0,1,.63,1.43,30.07,30.07,0,0,0-.36,5.52l.77,6.56a1.52,1.52,0,0,1-1.31,1.66l-3.4.4a1.51,1.51,0,0,1-.52,0l-13.48-3.22a6.56,6.56,0,0,0,.85,2c.89.6,10,4.7,18.17,8.24a1.49,1.49,0,0,1,.89,1.19L153,106a9.26,9.26,0,0,0,2.86,4.46,9.2,9.2,0,0,0,1.74-5l-.68-5.89a1.53,1.53,0,0,1,.58-1.37c7.1-5.32,15-11.43,15.8-12.27a6.19,6.19,0,0,0,.35-2l-12.37,6.26a1.39,1.39,0,0,1-.5.15l-3.41.39a1.5,1.5,0,0,1-1.65-1.31h0l-.77-6.57a30.66,30.66,0,0,0-1.62-5.28,1.54,1.54,0,0,1,.27-1.54c.82-.91,3-3.36,3.62-4.12a4.66,4.66,0,0,0,.07-1.18Zm0,0-5.1,3.08a1.44,1.44,0,0,1-1.23.14l-5.63-1.8a5.51,5.51,0,0,0,.3,1c.79.6,3.47,2.48,4.47,3.18a1.48,1.48,0,0,1,.63,1.43,30.07,30.07,0,0,0-.36,5.52l.77,6.56a1.52,1.52,0,0,1-1.31,1.66l-3.4.4a1.51,1.51,0,0,1-.52,0l-13.48-3.22a6.56,6.56,0,0,0,.85,2c.89.6,10,4.7,18.17,8.24a1.49,1.49,0,0,1,.89,1.19L153,106a9.26,9.26,0,0,0,2.86,4.46,9.2,9.2,0,0,0,1.74-5l-.68-5.89a1.53,1.53,0,0,1,.58-1.37c7.1-5.32,15-11.43,15.8-12.27a6.19,6.19,0,0,0,.35-2l-12.37,6.26a1.39,1.39,0,0,1-.5.15l-3.41.39a1.5,1.5,0,0,1-1.65-1.31h0l-.77-6.57a30.66,30.66,0,0,0-1.62-5.28,1.54,1.54,0,0,1,.27-1.54c.82-.91,3-3.36,3.62-4.12a4.66,4.66,0,0,0,.07-1.18Z"
                  transform="translate(0.01)"
                />
                <path
                  className="cls-2"
                  d="M157.1,70.58,152,73.66a1.48,1.48,0,0,1-1.23.14L145.14,72a4.43,4.43,0,0,0,.3,1c.79.6,3.47,2.48,4.47,3.18a1.49,1.49,0,0,1,.63,1.43,30,30,0,0,0-.36,5.52l.77,6.56a1.54,1.54,0,0,1-1.31,1.66l-3.4.4a1.51,1.51,0,0,1-.52,0l-13.48-3.22a6.32,6.32,0,0,0,.85,2c.89.6,10,4.7,18.17,8.24a1.47,1.47,0,0,1,.89,1.19l.69,5.89a9.28,9.28,0,0,0,2.86,4.46,9.28,9.28,0,0,0,1.74-5l-.68-5.89a1.51,1.51,0,0,1,.58-1.37c7.1-5.32,15-11.43,15.8-12.27a6.47,6.47,0,0,0,.35-2L161.12,90a2.21,2.21,0,0,1-.5.15l-3.41.39a1.5,1.5,0,0,1-1.65-1.31h0l-.77-6.57a30.39,30.39,0,0,0-1.62-5.28,1.52,1.52,0,0,1,.27-1.54c.82-.91,3-3.36,3.62-4.12a5.2,5.2,0,0,0,.07-1.18ZM129,85.52a1.07,1.07,0,0,1,.14-.16,1.49,1.49,0,0,1,1.38-.37l15.61,3.73,1.66-.19-.6-5.09a28.38,28.38,0,0,1,.24-5.37c-1.16-.81-3.33-2.34-4-2.83-.89-.7-1.45-2.48-1.68-5.28a1.49,1.49,0,0,1,1.36-1.61h0a1.62,1.62,0,0,1,.58.07l7.3,2.34,6.57-4a1.48,1.48,0,0,1,2,.5,1.59,1.59,0,0,1,.2.55c.43,2.78.29,4.64-.41,5.52A31.86,31.86,0,0,1,156.26,77a28.18,28.18,0,0,1,1.49,5.16l.59,5.08,1.66-.19,14.32-7.25a1.49,1.49,0,0,1,2,.66,1.46,1.46,0,0,1,.16.61c.07,1.45.09,5-1,6.43-.89,1.11-10.74,8.58-15.64,12.26l.6,5c.44,3.68-2.89,8-3.28,8.5h0a1.48,1.48,0,0,1-2.08.26h0c-.48-.38-4.73-3.84-5.16-7.52l-.59-5c-5.63-2.44-16.93-7.42-18-8.3-1.43-1.13-2.23-4.58-2.5-6A1.46,1.46,0,0,1,129,85.52Z"
                  transform="translate(0.01)"
                />
                <path
                  className="cls-3"
                  d="M139.27,84.4s-2.72-21-13.77-33.4"
                  transform="translate(0.01)"
                />
                <path
                  className="cls-3"
                  d="M164.55,76.9s-2.72-21-13.77-33.4"
                  transform="translate(0.01)"
                />
                <path
                  className="cls-3"
                  d="M149,65.17s-6.44-17.53-12.51-21.76"
                  transform="translate(0.01)"
                />
              </g>
              <g className="globe-wrapper">
                <circle className="cls-2" cx="91.16" cy="91.16" r="24.6" />
                <path
                  className="cls-4"
                  d="M91.15,113A21.81,21.81,0,0,1,71.5,81.59a3.55,3.55,0,0,0,3,1.6h3.1a4,4,0,0,1,2.82,1.17l6.78,6.78v4a4,4,0,0,0,4,4v7.84a2,2,0,0,0,2,2h.23a7.14,7.14,0,0,0,1.3-.18,3.5,3.5,0,0,0,2.58-2l5-10a8,8,0,0,0,.84-3.55v-2a4,4,0,0,0-4-4h-8a4.62,4.62,0,0,1-3.28-1.34,4,4,0,0,1-.2-5.42l6.19-7.2A9.76,9.76,0,0,0,95,69.6,21.85,21.85,0,0,1,91.15,113Z"
                  transform="translate(0.01)"
                />
              </g>
            </svg>
          </Box>
        </Box>
      )}
    </>
  );
}
