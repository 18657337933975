import { get, has, isObject } from 'lodash';
import useSchemaPath from './use-schema-path';

function getTitle(schema, path) {
  const property = get(schema?.properties || schema, path);

  return property?.title;
}

function getType(schema, path) {
  const property = get(schema?.properties || schema, path);

  return property?.type;
}

function useSchemaProperties(schemaRoot) {
  const { getSchemaPath, getSchemaPathAdditonalProperties } = useSchemaPath();
  const isArray = schemaRoot.type === 'array';

  function hasAdditionalProperties(path) {
    const property = [1, 2].find((step) => {
      const pathToAncestor = [...path].slice(0, path.length - step);
      const ancestor = get(schemaRoot?.properties || schemaRoot, pathToAncestor);
      const hasAddProps = has(ancestor, 'additionalProperties');

      return hasAddProps && isObject(get(ancestor, 'additionalProperties'));
    });

    return property > 0;
  }

  function formatPathForArray(path) {
    const strippedForIndex = path.slice(1, path.length);

    return ['items', 'properties', ...strippedForIndex];
  }

  const getSchemaTitle = (path) => {
    const isAddProps = hasAdditionalProperties(path);
    const isAddPropsProperties = isAddProps && path.length > 2;
    const schemaPath = isAddPropsProperties
      ? getSchemaPathAdditonalProperties(path)
      : getSchemaPath(path);

    const title =
      !isAddProps || path.length > 2
        ? getTitle(schemaRoot, isArray ? formatPathForArray(path) : schemaPath)
        : [...path].pop();

    return title;
  };

  const getSchemaType = (path) => {
    const schemaPath = getSchemaPath(path);

    return getType(schemaRoot, schemaPath);
  };

  return { getSchemaTitle, getSchemaType };
}

export default useSchemaProperties;
