import React from 'react';
import PropTypes from 'prop-types';
import useReducerAsync from '../use-reducer-async';
import SaveQueueContext from './save-queue-context';
import { getSaveQueue } from '../store';
import Reduce from '../reducer';

const SaveQueueProvider = ({ children }) => {
  const [state, dispatch] = useReducerAsync(Reduce, getSaveQueue());
  return (
    <SaveQueueContext.Provider value={[state, dispatch]}>
      {children}
    </SaveQueueContext.Provider>
  );
};

SaveQueueProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SaveQueueProvider;
