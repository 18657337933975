const exclusionsFlightsOrder = [
  'id',
  'flightDate',
  'languageCodes',
  'topLevelDomains',
  'flightNumbers',
  'bookingClasses',
  '*',
  'airport',
  'city',
  'country',
  'metaSearchOnly',
  'area',
  'activeFromToDates',
];

const exclusionsAirlinesOrder = [
  'airlines',
  'areas',
  'countries',
  'cities',
  'airports',
  'activeFromToDates',
  'metaSearchOnly',
];
const itinsRequestTypesOrder = ['id', 'cities', 'passengers', '*'];
const baggageInformationsOrder = ['id', '*', 'carryOn', 'checkIn'];

const maxConnectionTimeOrder = ['id', '*'];

const co2CompensationOrder = ['id', '*', 'longHaul'];

const exclusionsDeparturesArrivalsOrder = ['id', 'cities', 'airports', '*'];

const surchargesOrder = [
  'id',
  'topLevelDomains',
  'description',
  'surcharge',
  'surchargeType',
  'baggageOptions',
  'areas',
  'areasExclusions',
  'airports',
  'airportsExclusions',
  'ticketingAirlines',
  'marketingAirlines',
  'productTypes',
  'tripTypes',
  'noMaxDiscount',
  'surchargeLimit',
  'countries',
  'countriesExclusions',
  'cities',
  'citiesExclusions',
  'durationMinDays',
  'departureFromToDates',
  'activeFromToDates',
  'surchargeStart',
  'passengerGroups',
  'surchargeIn',
  'cabinClasses',
  'fareBasis',
  'flightNumbers',
  'languageCodes',
  'bookingClasses',
];

const ticketingAirlinesHardOrder = [
  'id',
  'topLevelDomains',
  'languageCodes',
  'originals',
  'replacement',
];

const defaultOrder = ['id', 'topLevelDomains', 'languageCodes'];

export const orderColumns = {
  baggageInformations: baggageInformationsOrder,
  co2Compensation: co2CompensationOrder,
  exclusionsFlights: exclusionsFlightsOrder,
  exclusionsDeparturesArrivals: exclusionsDeparturesArrivalsOrder,
  exclusionsAirlines: exclusionsAirlinesOrder,
  itinsRequestTypes: itinsRequestTypesOrder,
  maxConnectionTime: maxConnectionTimeOrder,
  surcharges: surchargesOrder,
  ticketingAirlinesHard: ticketingAirlinesHardOrder,
  defaultOrder,
};
