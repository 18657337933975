import React, { useMemo, useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oauth2-pkce';
import { saveAsync } from '../../services/flight-search-service';
import { saveQueueContext, reducers as saveQueueReducers } from '../../store/saveQueue';
import { Notification } from '../Notification';
import { spinnerContext } from '../../store/spinner';
import spinnerReducers from '../../store/spinner/spinner-reducers';
import { mainDataContext, reducers as mainDataReducers } from '../../store/mainData';
import { modalContext } from '../../store/modal';
import modalReducers from '../../store/modal/modal-reducers';

export default function Save() {
  const { authService } = useAuth();
  const authToken = authService.getAuthTokens();
  const logout = async () => authService.logout();

  const modalDispatch = useContext(modalContext)[1];
  const [mainData] = useContext(mainDataContext);
  const defaultNotificationState = {
    type: 'notice',
    display: false,
    text: '',
    isAutoClosing: true,
  };
  const [saveQueueState, saveQueueDispatch] = useContext(saveQueueContext);
  const spinnerDispatch = useContext(spinnerContext)[1];
  const [notificationProps, setNotificationProps] = useState(defaultNotificationState);
  const [saveStatus, setSaveStatus] = useState(null);
  const dispatch = useContext(mainDataContext)[1];

  const closeCallback = () => {
    setSaveStatus(null);
    setNotificationProps(defaultNotificationState);
  };

  const saveStatusActions = useMemo(
    () => ({
      Saving: { display: true, type: 'warning', text: 'Saving...' },
      Saved: { type: 'notice', text: 'Saved' },
      ValidationError: {
        type: 'warning',
        text: 'Validation errors',
        isAutoClosing: false,
      },
      SystemError: {
        type: 'error',
        text: 'Something went wrong or you do not have rights to make changes.',
        isAutoClosing: false,
      },
      Logout: {
        display: true,
        type: 'error',
        text: 'Your session has expired - you are being logged out!',
        isAutoClosing: false,
      },
    }),
    []
  );

  useEffect(() => {
    if (saveStatus === null) return;

    setNotificationProps((currentState) => ({
      ...currentState,
      ...saveStatusActions[saveStatus],
    }));
  }, [saveStatus, setNotificationProps, saveStatusActions]);

  useEffect(() => {
    if (saveQueueState.length === 0) return;

    const saveEffect = async () => {
      setSaveStatus('Saving');
      const saveJob = saveAsync(saveQueueState[0].payload, mainData, authToken);

      saveQueueDispatch({
        type: saveQueueReducers.REMOVE,
        payload: saveQueueState[0].key,
      });

      const saveResult = await saveJob;
      if (saveResult.ok) {
        dispatch({
          type: mainDataReducers.SET_MAIN_DATA,
          payload: saveResult.response,
        });

        setSaveStatus('Saved');
        spinnerDispatch({
          type: spinnerReducers.SHOW_SPINNER,
          payload: { show: false },
        });

        modalDispatch({
          type: modalReducers.SHOW_MODAL,
          payload: { show: false },
        });

        return;
      }

      if (saveResult.errorType === 'SystemError') {
        setSaveStatus('SystemError');
        spinnerDispatch({
          type: spinnerReducers.SHOW_SPINNER,
          payload: { show: false },
        });
        return;
      }

      setSaveStatus('ValidationError');
    };
    // API CALL
    const tokenExpired = new Date() > new Date(authToken.expires_at);
    if (tokenExpired) {
      setSaveStatus('Logout');
      setTimeout(() => {
        logout();
      }, 3000);
    } else {
      saveEffect();
    }
  }, [
    saveQueueState,
    saveQueueDispatch,
    notificationProps,
    setSaveStatus,
    spinnerDispatch,
  ]);

  return (
    <Notification
      heading="Save status"
      {...notificationProps}
      closeCallback={closeCallback}
    >
      <>{notificationProps.text}</>
    </Notification>
  );
}
