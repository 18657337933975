import Reducers from './main-navigation-reducers';

const newState = (state, change) => ({
    ...state,
    ...change
})

const actions = {
    [Reducers.SET_SELECTED]: (state, payload) => newState(state, { selected: payload })
};

export default actions;
