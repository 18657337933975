import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import './Modal.scss';
import { AppBar, Box, IconButton, useTheme } from '@mui/material';

function Modal({ isOpen, onClose, className, footerContent, children, showAppbar }) {
  const theme = useTheme();
  if (isOpen) {
    return (
      <Box
        className="modal__overlay"
        sx={{
          zIndex: theme.zIndex.modal,
        }}
      >
        <Box
          className={['modal__modal', className].join(' ')}
          sx={{ position: 'relative' }}
        >
          {showAppbar && (
            <AppBar
              color="inherit"
              sx={{
                position: 'absolute',
                width: 1,
                height: 64,
                zIndex: 1,
                color: 'white',
              }}
            >
              <IconButton
                color="primary"
                onClick={onClose}
                sx={{
                  position: 'absolute',
                  cursor: 'pointer',
                  zIndex: theme.zIndex.modal + 1,
                  top: theme.spacing(1.5),
                  right: theme.spacing(1),
                  alignSelf: 'end',
                }}
              >
                <CloseIcon />
              </IconButton>
            </AppBar>
          )}
          <Box className="modal__content">{children}</Box>
          {footerContent && <Box className="modal__footer">{footerContent}</Box>}
        </Box>
      </Box>
    );
  }

  return null;
}

Modal.defaultProps = {
  className: null,
  footerContent: null,
  showAppbar: false,
};

Modal.propTypes = {
  showAppbar: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  footerContent: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default Modal;
