import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'react-oauth2-pkce';
import {
  Box,
  Container,
  Typography,
  useTheme,
  MenuItem,
  InputLabel,
  AppBar,
  Select,
  Stack,
} from '@mui/material';
import { FormItem } from '../Form-item';
import PropTypes from 'prop-types';
import { LogOutButton } from '../../SecuredApp';

import { getNavigationData } from '../../services/flight-search-service';
import { mainNavigationContext, reducers } from '../../store/mainNavigation';
import { schemaContext, reducers as schemaReducers } from '../../store/schema';
import { mainDataContext, reducers as mainDataReducers } from '../../store/mainData';
import './index.css';

function MainNavigation({ children }) {
  const theme = useTheme();
  const { authService } = useAuth();
  const authToken = authService.getAuthTokens();
  const logout = async () => authService.logout();
  const history = useHistory();
  const [navigationData, setNavigationData] = useState([]);
  const [navigationOptions, setNavigationOptions] = useState([]);
  const [selectedNavigationOption, setSelectedNavigationOption] = useState({
    key: '',
    value: '',
  });

  const dispatch = useContext(mainNavigationContext)[1];
  const dispatchMainData = useContext(mainDataContext)[1];
  const dispatchSchema = useContext(schemaContext)[1];

  const historyChangeHandler = useCallback(
    async (location) => {
      if (navigationOptions.length === 0) return;

      const selectedNavigationData = navigationData.find(
        (x) => `/${x.pathname}` === location.pathname
      );
      dispatchSchema({
        type: schemaReducers.SET_SCHEMA,
        payload: {},
      });
      dispatch({
        type: mainDataReducers.SET_MAIN_DATA,
        payload: {},
      });
      dispatch({ type: reducers.SET_SELECTED, payload: selectedNavigationData });
      setSelectedNavigationOption(
        navigationOptions.find((x) => `/${x.value}` === location.pathname)
      );
    },
    [navigationData, navigationOptions, dispatch]
  );

  const handleOnChange = useCallback(
    (selectedOption) => {
      history.push(selectedOption);
    },
    [history]
  );

  useEffect(() => {
    const initialize = async () => {
      // API CALL
      const tokenExpired = new Date() > new Date(authToken.expires_at);
      if (tokenExpired) {
        setTimeout(() => {
          logout();
        }, 1);
      } else {
        const rs = await getNavigationData(authToken);
        setNavigationData(rs);
        setNavigationOptions(rs.toSelect());
      }
    };

    initialize();
  }, []);

  useEffect(
    () => history.listen(historyChangeHandler),
    [history, historyChangeHandler, navigationOptions]
  );

  useEffect(() => {
    if (window.location.pathname === '/' && navigationOptions.length > 0) {
      handleOnChange(navigationOptions[0].value);
    } else {
      historyChangeHandler(window.location);
    }
  }, [handleOnChange, historyChangeHandler, navigationOptions]);

  return (
    <>
      <Select
        value={selectedNavigationOption.value}
        variant="outlined"
        onChange={(e) => handleOnChange(e.target.value)}
        size="small"
        color="white"
        sx={{
          fontFamily: 'ItalianBlackItalic',
          textTransform: 'uppercase',
          '& .MuiSelect-select': {
            color: theme.palette.common.white,
            borderColor: theme.palette.common.white,
            py: 1.5,
            fontFamily: theme.typography.button.fontFamily,
          },
          '&.MuiInputBase-root:hover': {
            borderColor: 'rgba(255, 255, 255, 1)',
            '& fieldset': {
              borderColor: 'rgba(255, 255, 255,1)',
            },
          },
          '& fieldset': {
            borderColor: 'rgba(255, 255, 255, 0.5)',
          },
          '& .MuiSvgIcon-root': {
            fill: theme.palette.common.white,
          },
        }}
      >
        {navigationOptions.map((option, i) => (
          <MenuItem key={`navigationOptions-${option.value}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

MainNavigation.propTypes = {
  children: PropTypes.element,
};

export default MainNavigation;
