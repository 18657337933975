import Reducers from './schema-reducers';

const newState = (state, change) => ({
  ...change,
});

const actions = {
  [Reducers.SET_SCHEMA]: (state, payload) => newState(state, payload),
};

export default actions;
