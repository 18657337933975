import { createTheme } from '@mui/material/styles';
import { colors } from '../colors';

const stylesSimple = {
  overflow: 'visible',
  textTransform: 'none',
  paddingLeft: 0,
  paddingRight: 0,
  fontFamily: 'Italian',
  letterSpacing: 'initial',
  fontWeight: '600',
  '&:hover': {
    backgroundColor: 'unset',
    '&:after': {
      transition: 'inherit',
      top: '50%',
      height: '100%',
      minHeight: '30px',
      transform: 'translateY(-50%)',
      left: '-16px',
      right: '-16px',
      position: 'absolute',
      content: '""',
      backgroundColor: '#0000000A',
    },
  },
  '& .MuiTouchRipple-root': {
    left: '-10px',
    right: '-10px',
    height: '100%',
  },
};

const ButtonOverrides = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '.75rem',
          padding: '.5rem .875rem',
        },
        sizeMedium: {
          fontSize: '1rem',
          padding: '.75rem 1.25rem',
        },
        sizeLarge: {
          fontSize: '1.25rem',
          padding: '1.25rem 2.75rem',
        },
        root: {
          borderRadius: '0px',
          lineHeight: '1rem',
          fontFamily: 'ItalianBlackItalic, Arial, Helvetica Neue',
          letterSpacing: '1px',
        },
      },
      defaultProps: {
        disableElevation: false,
      },
    },
  },
});

export default ButtonOverrides.components?.MuiButton;
