import { createTheme } from '@mui/material/styles';
const theme = createTheme();

export const FormHelperTextOverrides = {
  styleOverrides: {
    root: {
      marginLeft: theme.spacing(1),
    },
  },
};
