const errorQueue = [];

function clone(object) {
    return JSON.parse(JSON.stringify(object));
} 

function errorToJson(value) {
    if (!(value instanceof Error)) return value;
        
    const error = {};

    Object.getOwnPropertyNames(value).forEach(key => {
        error[key] = value[key];
    });

    return error;
}

export const addToErrorQueue = error => {
    errorQueue.push(errorToJson(error));
    console.error('Error push to error queue:', errorQueue[errorQueue.length - 1]);
}

export const peekAtErrorQueue = () => errorQueue.map(e => clone(e));

export const clearErrorQueue = () => {
    errorQueue.splice(0,errorQueue.length);
}

export const flushErrorQueue = cb => {
    while(errorQueue.length > 0) {
        cb(errorQueue.shift());
    }
}