import Ajv from 'ajv';

import addFormats from 'ajv-formats';

export const JsonSchemaValidationError = (
  errors = [],
  message = 'Json did not match schema'
) => {
  const error = new Error(message);
  error.name = 'JsonSchemaValidationError';
  error.errors = errors;

  return error;
};

export default function validateJson(json, schema) {
  const ajv = new Ajv();
  addFormats(ajv, ['date', 'time']);
  const validate = ajv.compile(schema);
  const isValid = validate(json);

  return { isValid, errors: validate.errors || [] };
}
