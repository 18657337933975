import { createTheme } from '@mui/material/styles';
const theme = createTheme();

export const DrawerOverrides = {
  styleOverrides: {
    root: {
      '& .MuiDrawer-paper': {
        borderRadius: '2px 2px 0px 0px',
      },
      '& .closeButton': {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        zIndex: theme.zIndex.appBar + 1,
      },
    },
  },
};
