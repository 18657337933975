function filterFromTo(term, rowData, key) {
  const field = rowData[key];
  if (field !== undefined) {
    if (field.from !== undefined && field.from.toString().includes(term)) {
      return true;
    }
    if (field.to !== undefined && field.to.toString().includes(term)) {
      return true;
    }
  }

  return false;
}

function filterMinMax(term, rowData, key) {
  const field = rowData[key];
  if (field !== undefined) {
    if (field.minLength !== undefined && field.minLength.toString().includes(term)) {
      return true;
    }
    if (field.maxLength !== undefined && field.maxLength.toString().includes(term)) {
      return true;
    }
  }

  return false;
}

function filterStringified(term, rowData, key) {
  const field = rowData[key];
  if (JSON.stringify(field).toLocaleLowerCase().includes(term.toLocaleLowerCase())) {
    return true;
  }
  return false;
}

function filterDeparturesArrivals(term, rowData, key) {
  const field = rowData[key];
  if (field !== undefined) {
    if (
      field.departures !== undefined &&
      field.departures.join().toLowerCase().includes(term.toLowerCase())
    ) {
      return true;
    }
    if (
      field.arrivals !== undefined &&
      field.arrivals.join().toLowerCase().includes(term.toLowerCase())
    ) {
      return true;
    }
  }

  return false;
}

function sortFromTo(obj) {
  if (obj !== undefined) {
    if (obj.from !== undefined) {
      return new Date(obj.from);
    }
    if (obj.to !== undefined) {
      return new Date(obj.to);
    }
  }

  return new Date(0);
}

function sortMinMax(obj) {
  if (obj !== undefined) {
    if (obj.minLength !== undefined) {
      return new Date(obj.minLength);
    }
    if (obj.maxLength !== undefined) {
      return new Date(obj.maxLength);
    }
  }

  return new Date(0);
}

function sortDeparturesArrivals(obj) {
  if (obj !== undefined) {
    if (obj.departures !== undefined) {
      return obj.departures.join();
    }
    if (obj.arrivals !== undefined) {
      return obj.arrivals.join();
    }

    return '';
  }

  return '';
}

export {
  filterFromTo,
  filterMinMax,
  filterDeparturesArrivals,
  filterStringified,
  sortFromTo,
  sortMinMax,
  sortDeparturesArrivals,
};
