import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Tabs as MuiTabs,
  Tab,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { Modal } from '../Modal';
import { DialogContent } from '@material-ui/core';

const countryList = [
  { value: 'NO', label: 'Norway' },
  { value: 'SE', label: 'Sweden' },
  { value: 'DA', label: 'Denmark' },
  { value: 'NL', label: 'Nederlands' },
  { value: 'BE', label: 'Belgium' },
  { value: 'FI', label: 'Finland' },
  { value: 'IS', label: 'Iceland' },
];

export const Tabs = ({ tabs, onClick, displayComponent, selectedTab }) => {
  const [tabToDelete, setTabToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [tabToAdd, setTabToAdd] = useState(null);
  const [countriesToAdd, setCountriesToAdd] = useState([]);

  useEffect(() => {
    const countries = countryList.filter((country) => !tabs.includes(country.value));
    setCountriesToAdd(countries);
  }, [tabs]);

  const handleClick = (ev, tab) => {
    ev.preventDefault();
    if (tab === 'add') {
      setShowCreateModal((lastValue) => !lastValue);
    } else if (tab === 'delete') {
      handleDelete(ev, selectedTab);
    } else {
      onClick(tab);
    }
  };

  const handleCreate = () => {
    setShowCreateModal(true);
  };

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
    setTabToAdd(null);
  };

  const handleCreateTab = () => {
    setShowCreateModal(false);
  };

  const handleDelete = (ev, tab) => {
    ev.preventDefault();
    if (selectedTab !== '*') {
      setTabToDelete(tab);
      setShowDeleteModal(true);
    }
  };

  const handleDeleteModalClose = () => {
    setShowDeleteModal(false);
    setTabToDelete(null);
  };

  const handleDeleteTab = () => {
    setShowDeleteModal(false);
  };

  const getCountry = (abr) => {
    const name = countryList.find((country) => country.value === abr?.toUpperCase());
    return name ? name.label : 'UNKNOWN';
  };

  const tabSxStyles = {
    minWidth: 0,
    flexGrow: '1',
    fontFamily: 'Italian-regular',
    fontWeight: '600',
  };
  return (
    <Box sx={{ mb: 2 }}>
      <MuiTabs value={selectedTab} onChange={handleClick} sx={{ p: 1, mb: 2 }}>
        {tabs.map((tab, i) => (
          <Tab value={tab} key={`tab-${i}`} label={tab} sx={tabSxStyles} />
        ))}
        <Tab value="add" label="Add" sx={{ color: 'success.main', ...tabSxStyles }} />
        <Tab
          value="delete"
          disabled={selectedTab === '*'}
          color="primary"
          sx={{ color: 'error.main', ...tabSxStyles }}
          label="Delete"
        />
      </MuiTabs>

      {displayComponent}

      <Dialog open={showCreateModal} onClose={() => handleCreateModalClose()}>
        <DialogTitle>Select a domain you want to add specific settings to</DialogTitle>
        <DialogContent>
          <FormControl sx={{ width: 1 }}>
            <InputLabel id="demo-simple-select-helper-label">Select...</InputLabel>
            <Select label="Select...">
              {countriesToAdd.map((country, i) => (
                <MenuItem key={`country-option-${i}`} value={country.value}>
                  {country.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ mt: 2, mb: 1 }}>
            <Button variant="contained" onClick={handleCreateTab} sx={{ marginRight: 2 }}>
              Create tab
            </Button>
            <Button variant="text" onClick={handleCreateModalClose}>
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog open={showDeleteModal} onClose={() => handleDeleteModalClose()}>
        <DialogTitle>{`You are about to delete all settings for ${getCountry(
          tabToDelete
        )}`}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 1 }}>
            This action can not be undone. Are you sure you want to proceed?
          </Typography>
          <Box sx={{ mt: 2, mb: 1 }}>
            <Button variant="contained" onClick={handleDeleteTab} sx={{ marginRight: 2 }}>
              Delete
            </Button>
            <Button variant="outlined" onClick={handleDeleteModalClose}>
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

Tabs.defaultProps = {
  displayComponent: null,
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedTab: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  displayComponent: PropTypes.node,
};
