import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import ReactTooltip from 'react-tooltip';
import './index.css';
import { Box } from '@mui/material';

function DataItem({ label, value, className, showTooltip }) {
  const classNames = className ? `data-item ${className}` : 'data-item';

  if (showTooltip) {
    const tooltip = uuidv4();

    return (
      <Box className={classNames}>
        <span data-tip data-for={tooltip} className="data-item__label">
          {label && `${label.substring(0, 2)}:`}
        </span>
        <span className="data-item__value">{value}</span>

        <ReactTooltip id={tooltip}>
          <span>{label}</span>
        </ReactTooltip>
      </Box>
    );
  }

  return (
    <Box className={classNames}>
      <span className="data-item__label">{label}:</span>
      <span className="data-item__value">{value}</span>
    </Box>
  );
}

DataItem.defaultProps = {
  value: '',
  className: null,
  showTooltip: false,
  label: '',
};

DataItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  className: PropTypes.string,
  showTooltip: PropTypes.bool,
};

export default DataItem;
