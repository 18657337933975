import React from 'react';
import PropTypes from 'prop-types';
import NumberField from '@rjsf/core/lib/components/fields/NumberField';
import { Box, Typography } from '@mui/material';

function CustomArrayField(props) {
  return (
    <Box className="number-field-widget" sx={{ '& .MuiTextField-root': { width: 1 } }}>
      <NumberField {...props} />
    </Box>
  );
}

CustomArrayField.defaultProps = {
  formData: [],
};

CustomArrayField.propTypes = {
  name: PropTypes.string.isRequired,
  formData: PropTypes.any,
  schema: PropTypes.object.isRequired,
};

export default CustomArrayField;
