import { v4 as uuidv4 } from 'uuid';
import getConfig from '../config';
import {
  deepMerge,
  getChangedValues,
  deleteObjectRow,
  duplicateObjectRow,
} from '../utils';
import { addToErrorQueue } from './error-service';
import { get, put, post, deleteRow } from './api-service';
import validateJson, {
  JsonSchemaValidationError,
} from './json-schema-validation-service';

let cacheKey;
const renewCacheKey = () => {
  cacheKey = uuidv4();
};

const getBaseUrl = async () => getConfig('FLIGHT_SEARCH_API_BASE_URL');
const getWithCacheKey = async (urlPart, authToken) =>
  get(`${await getBaseUrl()}${urlPart}${cacheKey ? `?rnd=${cacheKey}` : ''}`, authToken);

const validate = (data, schema) => {
  const validationResult = validateJson(data, schema);
  if (!validationResult.isValid) {
    console.warn('validate -> validationResult.errors', validationResult.errors);
  }
};

export const getValidatedJson = async (urlPart, authToken) => {
  try {
    const url = `${await getBaseUrl()}${urlPart}`;
    const [data, schema] = await Promise.all([
      getWithCacheKey(urlPart, authToken),
      get(`${url}/schema`, authToken),
    ]);

    validate(data, schema);
    return {
      data,
      schema,
    };
  } catch (e) {
    return addToErrorQueue(e);
  }
};

function toNavigationData(apiDocJson) {
  const dataPaths = Object.keys(apiDocJson.paths).filter(
    (x) => x.indexOf('/schema') === -1
  );

  const getName = (propertyName) => apiDocJson.paths[propertyName].get.tags[0];
  const getPathName = (propertyName) =>
    getName(propertyName).toLowerCase().replace(/\s|\//g, '-');
  const getDescription = (propertyName) =>
    apiDocJson.tags.find((x) => x.name === getName(propertyName)).description;
  const getPutUrl = (propertyName) =>
    apiDocJson.paths[propertyName].put ? propertyName : null;
  const getSchemaUrl = (propertyName) =>
    apiDocJson.paths[`${propertyName}/schema`] ? `${propertyName}/schema` : null;

  const mapped = dataPaths
    .filter((x) => apiDocJson.paths[x].get)
    .map((x) => ({
      name: getName(x),
      description: getDescription(x),
      pathname: getPathName(x),
      getUrl: x,
      putUrl: getPutUrl(x),
      schemaUrl: getSchemaUrl(x),
    }));

  mapped.toSelect = () => mapped.map((x) => ({ label: x.name, value: x.pathname }));

  return mapped;
}

export const getNavigationData = async (authToken) => {
  try {
    const apiDocJson = await get(`${await getBaseUrl()}/v3/api-docs`, authToken);

    return toNavigationData(apiDocJson);
  } catch (e) {
    return addToErrorQueue(e);
  }
};

export const saveAsync = async (
  { getUrl, putUrl, schemaUrl, body, rowChange },
  mainData,
  authToken
) => {
  try {
    const baseUrl = await getBaseUrl();
    let resultBody = {};

    let result = null;

    if (rowChange.type === 'editRow') {
      resultBody = body;
      result = await put(`${baseUrl}${putUrl}`, body, authToken);
    } else if (rowChange.type === 'editRule') {
      resultBody = body;
      result = await put(`${baseUrl}${putUrl}`, resultBody, authToken);
    } else if (rowChange.type === 'add') {
      resultBody = body;
      result = await post(`${baseUrl}${putUrl}`, resultBody, authToken);
    } else if (rowChange.type === 'delete') {
      resultBody = body[rowChange.dataKey].find((x) => x._id === rowChange.rowIndex);
      delete resultBody.lastModified;
      result = await deleteRow(`${baseUrl}${putUrl}`, resultBody, authToken);
    } else {
      resultBody = body[rowChange.dataKey][rowChange.rowIndex];
      delete resultBody._id;
      result = await post(`${baseUrl}${putUrl}`, resultBody, authToken);
    }
    return { ok: true, response: result };
  } catch (error) {
    const isClientSchemaValidationError = () =>
      error.name === 'JsonSchemaValidationError';
    const isServerSchemaValidationError = () => error.status === 400;

    if (isServerSchemaValidationError()) {
      return {
        ok: false,
        errorType: 'ServerValidationError',
        error,
        saveData: { getUrl, putUrl, schemaUrl, body },
      };
    }

    if (!isClientSchemaValidationError()) addToErrorQueue(error);

    return {
      ok: false,
      errorType: isClientSchemaValidationError()
        ? 'ClientValidationError'
        : 'SystemError',
      error,
      saveData: { getUrl, putUrl, schemaUrl, body },
    };
  }
};
