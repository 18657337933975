import { createTheme } from '@mui/material/styles';
const theme = createTheme();

export const PaperOverrides = {
  styleOverrides: {
    root: {
      borderRadius: 0,
      boxShadow: theme.shadows[2],
    },
  },
};
