import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import MainNavigationContext from './main-navigation-context';
import { getMainNavigation } from '../store';
import Reduce from '../reducer';

const MainNavigationProvider = ({children}) => {
    const [state, dispatch] = useReducer(Reduce, getMainNavigation());
    
    return (
        <MainNavigationContext.Provider value={[state, dispatch]}>
            {children}
        </MainNavigationContext.Provider>
    );
}

MainNavigationProvider.propTypes = {
    children: PropTypes.node.isRequired
}

export default MainNavigationProvider;
