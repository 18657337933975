import mainNavigationActions from './mainNavigation/main-navigation-actions';
import saveQueueActions from './saveQueue/save-queue-actions';
import mainDataActions from './mainData/main-data-actions';
import schemaActions from './schema/schema-actions';

import spinnerActions from './spinner/spinner-actions';
import modalActions from './modal/modal-actions';

const actions = {
  ...mainNavigationActions,
  ...saveQueueActions,
  ...mainDataActions,
  ...schemaActions,
  ...spinnerActions,
  ...modalActions,
};

const Reduce = (state, action) => {
  const f = actions[action.type];

  return f ? f(state, action.payload) : state;
};

export default Reduce;
