import Reducers from './modal-reducers';

const newState = (state, change) => ({
  ...state,
  ...change,
});

const actions = {
  [Reducers.SHOW_MODAL]: (state, payload) => newState(state, payload),
};

export default actions;
