import React, { useState, useEffect, createContext, useContext } from 'react';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';

const CompactViewContext = createContext();

const useCompactView = () => useContext(CompactViewContext);

// Provider component to wrap around parts of the app that need compactView access
export const CompactViewProvider = ({ children }) => {
  const [cookies, setCookie] = useCookies(['compactView']);
  const [compactView, setCompactViewState] = useState(
    () =>
      // Get initial value from the cookie or default to false
      cookies.compactView === 'true'
  );

  // Update both the state and the cookie
  const setCompactView = (value) => {
    setCompactViewState((oldValue) => !oldValue);
    setCookie('compactView', !compactView, { path: '/', maxAge: 604800 * 31 }); // Expires in 31 days
  };

  // Provide the compactView state and setter function
  const value = { compactView, setCompactView };

  return (
    <CompactViewContext.Provider value={value}>{children}</CompactViewContext.Provider>
  );
};

export default useCompactView;

CompactViewProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
