import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isArray, isObject } from 'lodash';
import { RulesContext } from '../../contexts';
import { Toggle } from '../../Toggle';
import { Modal } from '../../Modal';
import { useDisplay } from '../../hooks';
import Editor from '../../Editor/Editor';
import { schemaContext } from '../../../store/schema';

import stripEmptyNestedObjects from '../../../utils/strip-empty-nested-objects';

import { mainDataContext } from '../../../store/mainData';
import { mainNavigationContext } from '../../../store/mainNavigation';
import { modalContext } from '../../../store/modal';
import modalReducers from '../../../store/modal/modal-reducers';
import spinnerReducers from '../../../store/spinner/spinner-reducers';
import { spinnerContext } from '../../../store/spinner';
import { saveQueueContext } from '../../../store/saveQueue';
import reducers from '../../../store/saveQueue/save-queue-reducers';
import generateUiSchema from '../../Editor/generateUiSchema';
import { Box, Paper, Container } from '@mui/material';
import MainNavigation from '../../MainNavigation/main-navigation';

function TableBasedForm({ dataKey, data, schema }) {
  const { definitions, properties } = schema;
  const { getDisplayComponent } = useDisplay(data, schema, [dataKey]);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [editorType, setEditorType] = useState('');
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [modalVisible] = useContext(modalContext);
  const modalDispatch = useContext(modalContext)[1];
  const saveQueueDispatch = useContext(saveQueueContext)[1];
  const spinnerDispatch = useContext(spinnerContext)[1];
  const [mainNavigationState] = useContext(mainNavigationContext);

  const cleanDataObj = (id, mainDataToClean) => {
    function cleanNestedData(obj) {
      if (obj !== undefined) {
        Object.keys(obj).forEach((key) => {
          if (Array.isArray(obj[key])) {
            obj[key] = obj[key].map((el) => {
              if (!isObject(el) && typeof el === 'string') {
                return el.trim();
              }
              return cleanNestedData(el);
            });
          }
        });
      }

      return obj;
    }
    if (isArray(mainDataToClean[dataKey])) {
      const selected = mainDataToClean[dataKey].find((obj) => obj._id === String(id));
      return [cleanNestedData(selected)];
    }
    if (isObject(mainDataToClean[dataKey])) {
      return mainDataToClean[dataKey];
    }

    return mainDataToClean[dataKey];
  };

  const handleSubmit = (formData, rowChange) => {
    saveQueueDispatch({
      type: reducers.ADD,
      payload: {
        getUrl: mainNavigationState.selected.getUrl,
        putUrl: mainNavigationState.selected.putUrl,
        schemaUrl: mainNavigationState.selected.schemaUrl,
        body: stripEmptyNestedObjects(formData),
        rowChange,
      },
    });
  };

  useEffect(() => {
    setSelectedData({ [dataKey]: cleanDataObj(selectedRowId, data) });
  }, [data, schema, selectedRowId]);

  const handleEditRow = (id) => {
    setEditorType('edit');
    setSelectedRowId(id);
    setSelectedData({ [dataKey]: cleanDataObj(id, data) });
    setShowModal(true);
    modalDispatch({
      type: modalReducers.SHOW_MODAL,
      payload: { show: true },
    });
  };

  const handleAddRow = () => {
    setEditorType('add');
    setShowModal(true);
    modalDispatch({
      type: modalReducers.SHOW_MODAL,
      payload: { show: true },
    });
  };

  const handleDeleteRow = (rowIndex) => {
    saveQueueDispatch({
      type: reducers.ADD,
      payload: {
        getUrl: mainNavigationState.selected.getUrl,
        putUrl: mainNavigationState.selected.putUrl,
        schemaUrl: mainNavigationState.selected.schemaUrl,
        body: data,
        rowChange: { dataKey, type: 'delete', rowIndex },
      },
    });
  };

  const handleDuplicateRow = (rowIndex) => {
    spinnerDispatch({
      type: spinnerReducers.SHOW_SPINNER,
      payload: { show: true },
    });

    saveQueueDispatch({
      type: reducers.DUPLICATE,
      payload: {
        getUrl: mainNavigationState.selected.getUrl,
        putUrl: mainNavigationState.selected.putUrl,
        schemaUrl: mainNavigationState.selected.schemaUrl,
        body: data,
        rowChange: { dataKey, type: 'duplicate', rowIndex },
      },
    });
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedData(null);
    modalDispatch({
      type: modalReducers.SHOW_MODAL,
      payload: { show: false },
    });
  };

  function editSelectedData() {
    const uiSchemaForSelected = generateUiSchema(schema, dataKey);
    return (
      <Box>
        {Object.keys(data).length !== 0 && (
          <Editor
            className={`editor--modal ${dataKey}-editor`}
            selectedSchema={schema.properties[dataKey].items}
            initialData={selectedData[dataKey][0]}
            uiSchema={uiSchemaForSelected}
            formContext={{ selectedData, path: [] }}
            rowChange={{ dataKey, type: 'editRow', rowIndex: selectedRowId }}
            handleSubmitForm={handleSubmit}
            dataKey={dataKey}
          />
        )}
      </Box>
    );
  }

  function createNewData() {
    const uiSchemaForSelected = generateUiSchema(schema, dataKey);
    const emptyData = {};
    emptyData[dataKey] = [{}];
    return (
      <Box>
        {Object.keys(data).length !== 0 && (
          <Editor
            className={`editor--modal ${dataKey}-editor`}
            selectedSchema={schema.properties[dataKey].items}
            initialData={emptyData}
            uiSchema={uiSchemaForSelected}
            formContext={{ selectedData, path: [] }}
            rowChange={{ dataKey, type: 'add', rowIndex: null }}
            handleSubmitForm={handleSubmit}
            dataKey={dataKey}
          />
        )}
      </Box>
    );
  }

  return (
    <RulesContext.Provider
      value={{
        editRow: handleEditRow,
        addRow: handleAddRow,
        deleteRow: handleDeleteRow,
        copyRow: handleDuplicateRow,
      }}
    >
      {modalVisible.show && (
        <Modal
          openModal={showModal}
          closeModal={handleModalClose}
          className="modal--editor modal__modal-form"
          showAppbar
        >
          <Box sx={{ mb: 2, position: 'absolute', top: 24, left: 32, zIndex: 2 }}>
            <Toggle />
          </Box>
          {editorType === 'edit' ? editSelectedData() : createNewData()}
        </Modal>
      )}
      <Paper
        elevation={2}
        sx={{
          p: 2,
          background: 'primary.main',
          minHeight: 4,
          maxWidth: { xs: 'initial' },
        }}
      >
        {getDisplayComponent()}
      </Paper>
    </RulesContext.Provider>
  );
}

TableBasedForm.propTypes = {
  schema: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  dataKey: PropTypes.string.isRequired,
};

export default TableBasedForm;
