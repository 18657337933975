/**
 * This function will recursively remove empty (nested) objects, kind of hacky :-(
 * It's needed because of form component creates object with empty values when submitteing. React json schema form library doesn't accept empty objects
 *
 * eg. console.log( stripEmptyNestedObjects({ keyA: 'valueA', keyB: 'valueB', keyC:""}) )
 * => { keyA: 'valueA', keyB: 'valueB'}
 *
 */
const stripEmptyNestedObjects = (o) => {
  for (const k in o) {
    if (!o[k] || typeof o[k] !== 'object') {
      continue;
    }

    // The property is an object
    stripEmptyNestedObjects(o[k]); // <-- Make a recursive call on the nested object
    if (Object.keys(o[k]).length === 0) {
      delete o[k];
    }
  }
  return o;
};

export default stripEmptyNestedObjects;
