import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.css';
import { Box } from '@mui/material';
import { Switch } from '../Switch';
import { useCompactView } from '../hooks';

function ToggleContainer({ className }) {
  const classNames = className ? `toggle ${className}` : 'toggle';
  const { compactView, setCompactView } = useCompactView();

  return (
    <Box className={classNames}>
      <Switch checked={!compactView} handleChange={() => setCompactView()} />
    </Box>
  );
}

ToggleContainer.defaultProps = {
  className: null,
};

ToggleContainer.propTypes = {
  className: PropTypes.string,
};

export default ToggleContainer;
