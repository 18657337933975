import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { StylesProvider, createGenerateClassName } from '@material-ui/styles';
import { MainNavigationProvider } from './store/mainNavigation';
import { MainDataProvider } from './store/mainData';
import { SchemaProvider } from './store/schema';
import { ModalProvider } from './store/modal';
import { SaveQueueProvider } from './store/saveQueue';
import { CompactViewProvider } from './components/hooks/use-compact-view'; // Adjust the path

import { MainNavigation } from './components/MainNavigation';
import FormHandler from './components/FormHandler/FormHandler';
import { Save } from './components/Save';
import { Spinner } from './components/Spinner';
import './App.scss';
import startApm from './rum';
import { LogOutButton } from './SecuredApp';
import { AppBar, Box, Container, Stack } from '@mui/material';

function App() {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'mt',
    seed: 'mt',
  });

  async function defaultHttpLoader() {
    const response = await fetch('/config/config.json');
    return response.json();
  }
  defaultHttpLoader().then((conf) => {
    startApm(conf);
  });

  return (
    <Box
      className="app"
      sx={{ pt: 13.5, backgroundColor: 'grey.100', minHeight: '100vh' }}
    >
      <StylesProvider generateClassName={generateClassName}>
        <BrowserRouter>
          <ModalProvider>
            <SaveQueueProvider>
              <CompactViewProvider>
                <SchemaProvider>
                  <MainDataProvider>
                    <MainNavigationProvider>
                      <AppBar sx={{ py: 2 }}>
                        <Container sx={{ maxWidth: { xs: 'initial' } }}>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <MainNavigation />
                            <img src="/kilroy-logo.svg" alt="Kilroy Logo" height={44} />
                            <LogOutButton />
                          </Stack>
                        </Container>
                      </AppBar>
                      <FormHandler />
                      <Save />
                    </MainNavigationProvider>
                  </MainDataProvider>
                </SchemaProvider>
              </CompactViewProvider>
            </SaveQueueProvider>
            <Spinner />
          </ModalProvider>
        </BrowserRouter>
      </StylesProvider>
    </Box>
  );
}

export default App;
