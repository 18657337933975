/**
 * Moves an object to the front of an array based on a key and value.
 * @param {*} arr
 * @param {*} key
 * @param {*} value
 * @returns
 */
export function moveObjectToFront(arr, key, value) {
  const index = arr.findIndex((obj) => obj[key] === value);
  if (index !== -1) {
    const [item] = arr.splice(index, 1);
    arr.unshift(item);
  }

  return arr;
}

export function moveElmToFront(arr, element) {
  const index = arr.indexOf(element);

  if (index !== -1) {
    // Remove the element from its current position
    const [item] = arr.splice(index, 1);

    // Move the element to the front
    arr.unshift(item);
  }

  return arr;
}
