const apiResponseError = (response) => {
  const error = new Error(`Request to ${response.url} failed!`);
  error.status = response.status;
  error.statusText = response.statusText;

  return error;
};

let hashCode = null;

export const get = async (url, authToken) => {
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken.access_token}`,
    },
  });

  if (response.ok) {
    if (response.headers.get('x-content-hash-code') !== null)
      hashCode = response.headers.get('x-content-hash-code');
    return response.json();
  }
  throw apiResponseError(response);
};

export const put = async (url, body, authToken, options = {}) => {
  const optionsObj = {
    ...options,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken.access_token}`,
      'X-Content-Hash-Code': hashCode,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  const response = await fetch(url, optionsObj);

  if (response.ok) {
    if (response.headers.get('x-content-hash-code') !== null)
      hashCode = response.headers.get('x-content-hash-code');
  }

  const data = await response.json();
  return data;
};

export const post = async (url, body, authToken, options = {}) => {
  const optionsObj = {
    ...options,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken.access_token}`,
      'X-Content-Hash-Code': hashCode,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  const response = await fetch(url, optionsObj);

  if (response.ok) {
    if (response.headers.get('x-content-hash-code') !== null)
      hashCode = response.headers.get('x-content-hash-code');
  }

  const data = await response.json();

  return data;
};

export const deleteRow = async (url, body, authToken, options = {}) => {
  const optionsObj = {
    ...options,
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${authToken.access_token}`,
      'X-Content-Hash-Code': hashCode,
    },
  };

  const response = await fetch(`${url}/${body._id}`, optionsObj);

  if (response.ok) {
    if (response.headers.get('x-content-hash-code') !== null)
      hashCode = response.headers.get('x-content-hash-code');
  }

  const data = await response.json();

  return data;
};
