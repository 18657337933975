import { createContext } from 'react';

const RulesContext = createContext({
  editRow: null,
  addRow: null,
  deleteRow: null,
  copyRow: null,
});

export default RulesContext;
