import {
  renderCheckBox,
  renderDaysOnDates,
  renderDeparturesArrivalsGeneric,
  renderException,
  renderJSONarray,
  renderMaxPriceDifference,
  renderMinMaxLengthGeneric,
  renderNestedObject,
  renderSplitArray,
  renderSplitArrayWithLineBreak,
  renderSurchargeIn,
} from './customTableCellRenderings';

import {
  filterDeparturesArrivals,
  filterMinMax,
  filterStringified,
  sortDeparturesArrivals,
  sortMinMax,
} from './customTableFilters';

export const columnsTypes = [
  {
    // Airports Exclusions Exclusions
    tooltip: 'Airports Exclusions',
    title: 'Airports Exclusions',
    field: 'airportsExclusions',
    name: 'airportsExclusions',
    render: (rowData) => renderDeparturesArrivalsGeneric(rowData, 'airportsExclusions'),
    customFilterAndSearch: (term, rowData) =>
      filterDeparturesArrivals(term, rowData, 'airportsExclusions'),
    customSort: (a, b) =>
      sortDeparturesArrivals(a.airportsExclusions).localeCompare(
        sortDeparturesArrivals(b.airportsExclusions)
      ),
  },
  {
    // Countries Exclusions
    tooltip: 'Countries Exclusions',
    title: 'Countries Exclusions',
    field: 'countriesExclusions',
    name: 'countriesExclusions',
    render: (rowData) => renderDeparturesArrivalsGeneric(rowData, 'countriesExclusions'),
    customFilterAndSearch: (term, rowData) =>
      filterDeparturesArrivals(term, rowData, 'countriesExclusions'),
    customSort: (a, b) =>
      sortDeparturesArrivals(a.countriesExclusions).localeCompare(
        sortDeparturesArrivals(b.countriesExclusions)
      ),
  },
  {
    // Country
    tooltip: 'Country',
    title: 'Country',
    field: 'country',
    name: 'country',
    render: (rowData) => renderDeparturesArrivalsGeneric(rowData, 'country'),
    customFilterAndSearch: (term, rowData) =>
      filterDeparturesArrivals(term, rowData, 'country'),
    customSort: (a, b) =>
      sortDeparturesArrivals(a.country).localeCompare(sortDeparturesArrivals(b.country)),
  },
  {
    // Country Settings
    render: (rowData) => renderJSONarray(rowData, 'countrySettings'),
    tooltip: 'Country Settings',
    title: 'Country Settings',
    field: 'countrySettings',
    name: 'countrySettings',
  },
  {
    // Days on dates
    tooltip: 'Days On Dates',
    title: 'Days On Dates',
    field: 'daysOnDates',
    name: 'daysOnDates',
    render: (rowData) => renderDaysOnDates(rowData, 'daysOnDates'),
  },
  {
    // Exceptions
    tooltip: 'Exceptions',
    title: 'Exceptions',
    field: 'exceptions',
    name: 'exceptions',
    render: (rowData) => renderException(rowData, 'exceptions'),
  },
  {
    // 'First Name Constraints'
    tooltip: 'First Name Constraints',
    title: 'First Name Constraints',
    field: 'firstNameConstraints',
    name: 'firstNameConstraints',
    render: (rowData) => renderMinMaxLengthGeneric(rowData, 'firstNameConstraints'),
    customFilterAndSearch: (term, rowData) =>
      filterMinMax(term, rowData, 'firstNameConstraints'),
    customSort: (a, b) =>
      sortMinMax(a.firstNameConstraints) - sortMinMax(b.firstNameConstraints),
  },
  {
    // 'Last Name Constraints'
    tooltip: 'Last Name Constraints',
    title: 'Last Name Constraints',
    field: 'lastNameConstraints',
    name: 'lastNameConstraints',
    render: (rowData) => renderMinMaxLengthGeneric(rowData, 'lastNameConstraints'),
    customFilterAndSearch: (term, rowData) =>
      filterMinMax(term, rowData, 'lastNameConstraints'),
    customSort: (a, b) =>
      sortMinMax(a.lastNameConstraints) - sortMinMax(b.lastNameConstraints),
  },
  {
    // longHaul
    tooltip: 'Long Haul',
    title: 'Long Haul',
    field: 'longHaul',
    name: 'longHaul',
    render: (rowData) => renderNestedObject(rowData, 'longHaul'),
    customFilterAndSearch: (term, rowData) =>
      filterStringified(term, rowData, 'longHaul'),
  },
  {
    // Lower Case Special Characters
    tooltip: 'Lower Case Special Characters',
    title: 'Lower Case Special Characters',
    field: 'lowerCaseSpecialCharacters',
    name: 'lowerCaseSpecialCharacters',
    render: (rowData) => renderSplitArray(rowData, 'lowerCaseSpecialCharacters'),
  },
  {
    // Upper Case Special Characters
    tooltip: 'Upper Case Special Characters',
    title: 'Upper Case Special Characters',
    field: 'upperCaseSpecialCharacters',
    name: 'upperCaseSpecialCharacters',
    render: (rowData) => renderSplitArray(rowData, 'upperCaseSpecialCharacters'),
  },
  {
    // shortHaul
    field: 'shortHaul',
    name: 'shortHaul',
    render: (rowData) => renderNestedObject(rowData, 'shortHaul'),
    customFilterAndSearch: (term, rowData) =>
      filterStringified(term, rowData, 'shortHaul'),
  },
  {
    // Meta Partner Codes
    tooltip: 'Meta Partner Codes',
    title: 'Meta Partner Codes',
    field: 'metaPartnerCodes',
    name: 'metaPartnerCodes',
    render: (rowData) => renderSplitArray(rowData, 'metaPartnerCodes'),
  },
  {
    // Metasearch only
    tooltip: 'Meta Search Only',
    title: 'MSO',
    field: 'metaSearchOnly',
    name: 'metaSearchOnly',
    render: (rowData) => renderCheckBox(rowData, 'metaSearchOnly'),
  },
  {
    // Max Price Difference
    tooltip: 'maxPriceDifference',
    title: 'Max Price Difference',
    field: 'maxPriceDifference',
    name: 'maxPriceDifference',
    render: (rowData) => renderMaxPriceDifference(rowData, 'maxPriceDifference'),
  },
  {
    // Originals
    tooltip: 'Originals',
    title: 'Originals',
    field: 'originals',
    name: 'originals',
    render: (rowData) => renderSplitArray(rowData, 'originals'),
  },
  {
    // Price Difference Filters
    tooltip: 'Price Difference Filters',
    title: 'Price Difference Filters',
    field: 'priceDifferenceFilters',
    name: 'priceDifferenceFilters',
    render: (rowData) => renderSplitArrayWithLineBreak(rowData, 'priceDifferenceFilters'),
  },
  {
    // Replacements
    tooltip: 'Replacements',
    title: 'Replacements',
    field: 'replacements',
    name: 'replacements',
    render: (rowData) => renderSplitArray(rowData, 'replacements'),
  },
  {
    // Surcharge In
    tooltip: 'Surcharge In',
    title: 'Surcharge In',
    field: 'surchargeIn',
    name: 'surchargeIn',
    render: (rowData) => renderSurchargeIn(rowData, 'surchargeIn'),
  },
  {
    // Umbraco Id
    tooltip: 'Umbraco Id',
    title: 'Umbraco Id',
    field: 'umbracoId',
    name: 'umbracoId',
  },
];
