import React, { useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oauth2-pkce';
import { mainNavigationContext } from '../../store/mainNavigation';
import { mainDataContext, reducers as mainDataReducers } from '../../store/mainData';
import { schemaContext, reducers as schemaReducers } from '../../store/schema';
import { isArray, isObject } from 'lodash';

import { getValidatedJson } from '../../services/flight-search-service';
import { spinnerContext } from '../../store/spinner';
import spinnerReducers from '../../store/spinner/spinner-reducers';
import modalReducers from '../../store/modal/modal-reducers';
import { modalContext } from '../../store/modal';
import { Container, Paper, Box } from '@mui/material';
import MainNavigation from '../MainNavigation/main-navigation';

import TableBasedForm from '../FormLayouts/TableBasedForm/TableBasedForm';
import CountryBasedFormContainer from '../FormLayouts/CountryBasedForm/CountryBasedForm';
import CleanForm from '../FormLayouts/CleanForm/CleanForm';

function FormHandler() {
  const [mainData] = useContext(mainDataContext);
  const [mainNavigation] = useContext(mainNavigationContext);
  const [schema] = useContext(schemaContext);
  const dispatch = useContext(mainDataContext)[1];
  const dispatchSchema = useContext(schemaContext)[1];
  const [payload, setPayload] = useState(null);
  const [showSpinner] = useContext(spinnerContext);

  useEffect(() => {
    if (
      schema.properties &&
      mainData &&
      Object.keys(mainData)[0] === Object.keys(schema.properties)[0]
    ) {
      const rootPropertyName = Object.keys(mainData)[0];

      const rootProperty = () => mainData[rootPropertyName];

      const isCountrySpecific = '*' in rootProperty() || 'dk' in rootProperty();
      const isTableBased = Array.isArray(mainData[rootPropertyName]);

      if (isCountrySpecific) {
        setFormType('country');
      } else if (isTableBased) {
        setFormType('table');
      } else {
        setFormType('clean');
      }
    } else {
      setFormType('');
    }
  }, [mainData, schema, mainNavigation]);

  const dispatchSpinner = useContext(spinnerContext)[1];
  const { authService } = useAuth();
  const logout = async () => authService.logout();
  const authToken = authService.getAuthTokens();

  const [formType, setFormType] = useState('');

  const dispatchModal = useContext(modalContext)[1];

  function saveMainData(data) {
    dispatchModal({
      type: modalReducers.SHOW_MODAL,
      payload: { show: false },
    });
    dispatchSpinner({
      type: spinnerReducers.SHOW_SPINNER,
      payload: { show: false },
    });
    dispatchSchema({
      type: schemaReducers.SET_SCHEMA,
      payload: data.schema,
    });
    dispatch({
      type: mainDataReducers.SET_MAIN_DATA,
      payload: data.data,
    });
  }

  useEffect(() => {
    if (mainNavigation.selected === null) return;
    const loadData = async () => {
      dispatchSpinner({
        type: spinnerReducers.SHOW_SPINNER,
        payload: { show: true },
      });
      // API CALL
      const tokenExpired = new Date() > new Date(authToken.expires_at);
      if (tokenExpired) {
        setTimeout(() => {
          logout();
          dispatchSpinner({
            type: spinnerReducers.SHOW_SPINNER,
            payload: { show: false },
          });
        }, 1);
      } else {
        saveMainData(await getValidatedJson(mainNavigation.selected.getUrl, authToken));
      }
    };
    loadData();
  }, [mainNavigation]);

  const dataKey = mainData ? Object.keys(mainData)[0] : null;

  const getMaxWidth = () => {
    if (formType === 'table') {
      return { maxWidth: { xs: 'initial' } };
    }
    return {};
  };

  return (
    <>
      <Container sx={{ ...getMaxWidth(), pb: 4 }}>
        {schema.properties &&
          mainData &&
          Object.keys(mainData)[0] === Object.keys(schema.properties)[0] && (
            <>
              {formType === 'country' && (
                <CountryBasedFormContainer
                  data={mainData}
                  dataKey={Object.keys(mainData)[0]}
                  schema={schema}
                />
              )}
              {formType === 'table' && (
                <TableBasedForm
                  data={mainData}
                  schema={schema}
                  dataKey={Object.keys(mainData)[0]}
                />
              )}
              {formType === 'clean' && (
                <CleanForm
                  data={mainData}
                  schema={schema}
                  dataKey={Object.keys(mainData)[0]}
                />
              )}
            </>
          )}
      </Container>
    </>
  );
}

export default FormHandler;
