import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import ModalContext from './modal-context';
import { getModal } from '../store';
import Reduce from '../reducer';

const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reduce, getModal());

  return (
    <ModalContext.Provider value={[state, dispatch]}>{children}</ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ModalProvider;
