const store = {
  mainNavigation: {
    selected: null,
  },
  schema: {},
  mainData: {},
  showSpinner: { show: false },
  modalVisible: { show: false },
  saveQueue: [],
};

export const getSpinner = () => store.showSpinner;
export const getModal = () => store.modalVisible;
export const getSchema = () => store.schema;

export const getMainData = () => store.mainData;
export const getMainNavigation = () => store.mainNavigation;
export const getSaveQueue = () => store.saveQueue;
