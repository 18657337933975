import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { saveQueueContext } from '../../../store/saveQueue';
import { mainNavigationContext } from '../../../store/mainNavigation';
import stripEmptyNestedObjects from '../../../utils/strip-empty-nested-objects';
import reducers from '../../../store/saveQueue/save-queue-reducers';
import Editor from '../../Editor/Editor';
import uiSchema from '../../Editor/ui-schema';

import { Box, Container, Paper } from '@mui/material';
import { Toggle } from '../../Toggle';

function CleanForm({ dataKey, data, schema }) {
  const saveQueueDispatch = useContext(saveQueueContext)[1];
  const [mainNavigationState] = useContext(mainNavigationContext);

  const handleSubmit = (formData, rowChange) => {
    saveQueueDispatch({
      type: reducers.ADD,
      payload: {
        getUrl: mainNavigationState.selected.getUrl,
        putUrl: mainNavigationState.selected.putUrl,
        schemaUrl: mainNavigationState.selected.schemaUrl,
        body: stripEmptyNestedObjects(formData),
        rowChange,
      },
    });
  };

  if (!data.hasOwnProperty(dataKey)) {
    return <div>Data not found</div>;
  }

  return (
    <Container sx={{ px: { xs: 0 }, my: 0 }}>
      <Paper>
        <Container sx={{ pt: 3, pb: 4 }}>
          <Box sx={{ mb: 2 }}>
            <Toggle />
          </Box>
          {Object.keys(data).length !== 0 && data.hasOwnProperty(dataKey) && (
            <Editor
              className={`clean-form ${dataKey}-editor`}
              selectedSchema={schema.properties[dataKey]}
              uiSchema={uiSchema}
              formContext={{ data, path: '' }}
              rowChange={{ dataKey, type: 'editRule', rowIndex: null }}
              initialData={data[dataKey]}
              handleSubmitForm={handleSubmit}
              dataKey={dataKey}
            />
          )}
        </Container>
      </Paper>
    </Container>
  );
}

CleanForm.propTypes = {
  dataKey: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
};

export default CleanForm;
