import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';

function MultiValueTextInput(props) {
  const { value, onChange } = props;
  const [inputValue, setInputValue] = useState();
  const [values, setValues] = useState();

  useEffect(() => {
    const selectedValues = value.map((val) => createOption(val));
    setValues(selectedValues);
  }, []);

  const handleChange = (val) => {
    setValues(val);
  };

  const handleInputChange = (inputVal) => {
    setInputValue(inputVal);
  };

  const handleKeyDown = (ev) => {
    if (!inputValue) return;

    switch (ev.key) {
      case 'Enter':
      case 'Tab':
        if (getDuplicates().length < 1) {
          setValues([...values, createOption(inputValue)]);
          onChange(values);
        }
        setInputValue('');
        ev.preventDefault();
        break;
      default:
        ev.preventDefault();
        break;
    }
  };

  function getDuplicates() {
    return values.filter((val) => val.value === inputValue);
  }

  function createOption(label) {
    return { label, value: label };
  }

  return (
    <CreatableSelect
      components={{ DropdownIndicator: null }}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder="Type something and press enter..."
      value={values}
    />
  );
}

MultiValueTextInput.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ])
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiValueTextInput;
