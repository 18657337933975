import { v4 as uuidv4 } from 'uuid';
import Reducers from './save-queue-reducers';

const newState = (state, change) => [
  ...state,
  {
    key: uuidv4(),
    payload: change,
  },
];

const actions = {
  [Reducers.ADD]: (state, payload) => newState(state, payload),
  [Reducers.REMOVE]: (state, payload) => state.filter((x) => x.key !== payload),
  [Reducers.DUPLICATE]: (state, payload) => newState(state, payload),
};

export default actions;
