import { createTheme } from '@mui/material';
import { colors } from '../colors';

const theme = createTheme({});
export const InputTextOverrides = {
  styleOverrides: {
    root: {
      '& .Mui-focused': {
        '& fieldset.MuiOutlinedInput-notchedOutline': {
          borderColor: colors.secondary.main,
        },
      },
      '& .MuiInputLabel-root.Mui-focused': {
        borderColor: colors.secondary.main,
        color: colors.secondary.main,
      },
      '& .MuiInputBase-formControl.Mui-error': {
        overflow: 'hidden',
        animationName: 'animateMargin',
        animationDuration: `${theme.transitions.duration.shortest}ms`,
        animationTimingFunction: theme.transitions.easing.sharp,
        marginBottom: theme.spacing(2),
      },
      '& .MuiFormHelperText-root.Mui-error': {
        position: 'absolute',
        bottom: theme.spacing(-1),
        animationName: 'animateOpacity',
        animationDuration: `${theme.transitions.duration.shortest * 2}ms`,
        animationTimingFunction: theme.transitions.easing.sharp,
      },
      '@keyframes animateMargin': {
        '0%': {
          marginBottom: '0',
        },
        '100%': {
          marginBottom: theme.spacing(2),
        },
      },
      '@keyframes animateOpacity': {
        '0%': {
          opacity: 0,
        },
        '50%': {
          opacity: 0,
        },
        '100%': {
          opacity: 1,
        },
      },
    },
  },
};
