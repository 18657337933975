import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import SpinnerContext from './spinner-context';
import { getSpinner } from '../store';
import Reduce from '../reducer';

const SpinnerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reduce, getSpinner());

  return (
    <SpinnerContext.Provider value={[state, dispatch]}>
      {children}
    </SpinnerContext.Provider>
  );
};

SpinnerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SpinnerProvider;
