import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import SchemaContext from './schema-context';
import { getSchema } from '../store';
import Reduce from '../reducer';

const SchemaProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reduce, getSchema());
  return (
    <SchemaContext.Provider value={[state, dispatch]}>{children}</SchemaContext.Provider>
  );
};

SchemaProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SchemaProvider;
