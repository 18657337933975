import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import { ThemeProvider } from '@mui/material/styles';

import { SpinnerProvider } from './store/spinner';
import theme from './styles/theme';
import { WrappedSecuredApp as SecuredApp } from './SecuredApp';
import App from './App';

import './index.css';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <CookiesProvider>
    <ThemeProvider theme={theme}>
      <SpinnerProvider>
        <SecuredApp>
          <App />
        </SecuredApp>
      </SpinnerProvider>
    </ThemeProvider>
  </CookiesProvider>,
  document.getElementById('app-root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
