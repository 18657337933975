import { useState } from 'react';

function getPath(path) {
  const { length } = path;
  let schemaPath = path;

  if (length > 1) {
    schemaPath = path.reduce((acc, bit, index) => {
      if (length > 1 && index !== length - 1) {
        return [...acc, bit, 'properties'];
      }

      return [...acc, bit];
    }, []);
  }

  return schemaPath;
}

function useSchemaPath() {
  const [schemaPath, setSchemaPath] = useState('');

  const setPathToSchema = (path) => {
    setSchemaPath(getPath(path));
  };

  const getSchemaPath = (path) => getPath(path);

  const getSchemaPathAdditonalProperties = (path) => {
    const temp = [...path];
    temp.splice(1, 1, 'additionalProperties', 'properties');

    return temp;
  };

  return { schemaPath, setPathToSchema, getSchemaPath, getSchemaPathAdditonalProperties };
}

export default useSchemaPath;
