import { orderColumns } from './FieldOrdering/field-ordering';

const uiSchema = {
  co2Compensation: {
    'ui:options': { orderable: true },
    'ui:order': orderColumns.co2Compensation,
    classNames: 'field-array--parent',
  },
  itinsRequestTypes: {
    'ui:options': { orderable: true },
    'ui:order': orderColumns.itinsRequestTypes,
    classNames: 'field-array--parent',
  },
  surcharges: {
    items: {
      'ui:options': { orderable: true },
      'ui:order': orderColumns.surcharges,
      classNames: 'field-array--parent',
    },
  },
  replacements: {
    classNames: 'field-array--parent',
    properties: {
      classNames: 'array__item',
    },
  },
  replacementsExtensions: {
    classNames: 'field-array--parent',
    properties: {
      classNames: 'array__item',
    },
  },
  maxConnectionTime: {
    'ui:options': { orderable: true },
    classNames: 'field-array--parent',
    items: {
      'ui:order': orderColumns.maxConnectionTime,
      classNames: 'array__item',
      items: {},
    },
  },
  exclusionsDeparturesArrivals: {
    classNames: 'departure-arrivals',
  },
  exclusions: {
    departureArrivals: {
      classNames: 'departure-arrivals',
    },
    preExclusions: {
      classNames: 'field-array--parent pre-exclusions',
      items: {
        classNames: 'array__item',
      },
    },
    postExclusions: {
      classNames: 'field-array--parent post-exclusions',
      items: {
        classNames: 'array__item',
      },
    },
  },
  exclusionsFlights: {
    items: {
      'ui:options': { orderable: true },
      'ui:order': orderColumns.exclusionsFlights,
    },
  },
  exclusionsAirlines: {
    classNames: 'inline-fields activeFromToDates',
    items: {
      'ui:options': { orderable: true },
      'ui:order': orderColumns.exclusionsAirlines,
      activeFromToDates: {
        'ui:order': ['from', 'to'],
        classNames: 'inline-fields activeFromToDates',
      },
      areas: {
        'ui:order': ['departures', 'arrivals'],
        classNames: 'inline-fields activeFromToDates',
      },
      topLevelDomains: {
        classNames: 'inline-fields',
      },
      airport: {
        'ui:order': ['departure', 'arrival'],
        classNames: 'inline-fields',
      },
      city: {
        'ui:order': ['departure', 'arrival'],
        classNames: 'inline-fields',
      },
      country: {
        'ui:order': ['departure', 'arrival'],
        classNames: 'inline-fields',
      },
    },
  },
};
export default uiSchema;
