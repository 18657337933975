let config = null;

const settings = {};
settings.configurationLoader = async function defaultHttpLoader() {
  const response = await fetch('/config/config.json');
  return response.json();
};

export default async function getConfig(key) {
  if (!config) {
    config = await settings.configurationLoader();
  }

  const val = config[key];

  if (val === undefined) {
    throw new Error(`Missing key in CONFIG! ${key}`);
  }

  return val;
}

export { settings };
