export const colors = {
  action: {
    hover: 'rgba(0, 0, 0, 0.08)',
    hoverOpacity: 0.08,
  },
  white: {
    main: '#ffffff',
  },
  black: {
    main: '#212121',
  },
  primary: {
    main: '#ff3c00',
    dark: '#E63600',
    light: '#FF764D',
  },
  secondary: {
    main: '#007DFF',
    dark: '#006FE6',
    light: '#4FAAFF',
  },
  grey: {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BDBDBD',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  },
  error: {
    main: '#d32f2f',
    dark: '#541313',
    light: '#ef5350',
    border: '#e78a8a',
    ultraLight: '#fbeaea',
  },
  warning: {
    main: '#fad63e',
    dark: '#5F2B01',
    light: '#ff9800',
    border: '#f5ae74',
    ultraLight: '#fdf0e6',
  },
  success: {
    main: '#2e7d32',
    dark: '#123214',
    light: '#4caf50',
    border: '#8ab68c',
    ultraLight: '#eaf2ea',
  },
};
