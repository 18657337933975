import { createTheme } from '@mui/material/styles';
import { colors } from './colors';
import { breakpoints } from './breakpoints';

const theme = createTheme({
  breakpoints,
  palette: colors,
});

const transition = `font-size ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeIn}`;

const italian = 'Italian, -apple-system, ItalianPlate';
const italianBlackItalic = 'ItalianBlackItalic, -apple-system, ItalianPlate';

export const typography = (bodyFont = italian, displayFont = italianBlackItalic) => ({
  fontFamily: bodyFont,
  fontWeight: 400,
  p: {
    fontWeight: 400,
    letterSpacing: 0.15,
  },

  h1: {
    fontFamily: displayFont,
    fontWeight: 400,
    fontSize: '37px',
    textTransform: 'uppercase',
    transition,
    [theme.breakpoints.up('sm')]: {
      fontSize: '56px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '72px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '90px',
    },
  },
  h2: {
    fontFamily: displayFont,
    fontWeight: 400,
    fontSize: '27px',
    textTransform: 'uppercase',
    transition,
    [theme.breakpoints.up('sm')]: {
      fontSize: '40px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '52px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '52px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '58px',
    },
  },
  h3: {
    fontFamily: displayFont,
    fontWeight: 400,
    fontSize: '24px',
    textTransform: 'uppercase',
    transition,
    [theme.breakpoints.up('md')]: {
      fontSize: '28px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '34px',
    },
  },
  h4: {
    fontSize: '22px',
    fontWeight: 600,
    transition,
    [theme.breakpoints.up('md')]: {
      fontSize: '26px',
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '32px',
    },
  },
  h5: {
    fontSize: '20px',
    fontWeight: 600,
    transition,
    [theme.breakpoints.up('xl')]: {
      fontSize: '26px',
    },
  },
  h6: {
    fontSize: '17px',
    fontWeight: 600,
    [theme.breakpoints.up('xl')]: {
      fontSize: '21px',
    },
  },

  bodySmall: {
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: 0.15,
  },
  bodyMedium: {
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: 0.15,
  },
  bodyLarge: {
    fontSize: '18px',
    lineHeight: '27px',
    letterSpacing: 0.15,
  },

  label: {
    fontSize: '13px',
    lineHeight: '18px',
    letterSpacing: 0.15,
  },
  caption: {
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: 0.15,
  },
  miniText: {
    fontSize: '10px',
    lineHeight: '15px',
    letterSpacing: 0,
  },

  subtitleLarge: {
    fontFamily: displayFont,
    fontWeight: 400,
    textTransform: 'uppercase',
    fontSize: '18px',
    lineHeight: '25px',
    letterSpacing: 0.45,
  },
  subtitleMedium: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '23px',
    letterSpacing: 0.15,
    transition,
  },
  subtitleSmall: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: 0.15,
    transition,
  },
  subtitleExtraSmall: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: 0.15,
    transition,
  },
  buttonLarge: {
    fontFamily: displayFont,
    fontWeight: 400,
    textTransform: 'uppercase',
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: 0.45,
  },
  buttonMedium: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: 0.45,
    transition,
  },
  buttonSmall: {
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: '22px',
    letterSpacing: 0.15,
    transition,
  },
  overline: {
    fontWeight: 300,
    fontSize: '13px12',
    lineHeight: '22px',
    letterSpacing: 0.15,
    transition,
  },
  strikethrough: {
    fontWeight: 700,
    textDecoration: 'line-through',
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: 0.45,
    transition,
  },
  accentHeading: {
    fontFamily: displayFont,
    fontWeight: 400,
    textTransform: 'uppercase',
    fontSize: '15px',
    lineHeight: '22px',
    letterSpacing: 0.45,
  },
  tooltip: {
    fontSize: theme.spacing(1.25),
    lineHeight: '1.4',
    color: theme.palette.common.black,
    opacity: '0.6',
    display: 'block',
  },
  error: {
    color: theme.palette.primary.main,
    fontWeight: 400,
    fontSize: theme.spacing(1.5),
    lineHeight: '1.66',
    textAlign: 'left',
    marginTop: '3px',
    marginRight: theme.spacing(2),
    marginBottom: '0',
    marginLeft: theme.spacing(1),
  },
});
