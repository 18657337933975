/**
 * Fields must be structured in certain ways
 * eg.:
 * - from - to instead of to - from
 * - departures instead of arrivals
 */

const fieldInnerOrder = {
  activeFromToDates: ['from', 'to'],
  airport: ['departure', 'arrival'],
  airports: ['departures', 'arrivals'],
  airportsExclusions: ['departures', 'arrivals'],
  area: ['departure', 'arrival'],
  areas: ['departures', 'arrivals'],
  areasExclusions: ['departures', 'arrivals'],
  city: ['departure', 'arrival'],
  cities: ['departures', 'arrivals'],
  citiesExclusions: ['departures', 'arrivals'],
  countries: ['departures', 'arrivals'],
  countriesExclusions: ['departures', 'arrivals'],
  country: ['departure', 'arrival'],
  departureFromToDates: ['from', 'to'],
  passengers: ['from', 'to'],
};

export default fieldInnerOrder;
