import Reducers from './main-data-reducers';

const newState = (state, change) => ({
  ...change,
});

const actions = {
  [Reducers.SET_MAIN_DATA]: (state, payload) => newState(state, payload),
};

export default actions;
