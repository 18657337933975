import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { usePrevious } from '../hooks';
import { Box, Typography, Alert, AlertTitle, Snackbar, Slide } from '@mui/material';

function NotificationContainer({
  display,
  heading,
  className,
  closeCallback,
  autoCloseDelay,
  children,
  type,
  isAutoClosing,
}) {
  const [isOpen, setIsOpen] = useState(display);
  const [autoClosingTimerId, setAutoClosingTimerId] = useState(0);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    clearTimeout(autoClosingTimerId);
    setAutoClosingTimerId(0);

    if (closeCallback) closeCallback();
  }, [closeCallback, autoClosingTimerId]);

  useEffect(() => {
    if (!isAutoClosing || !isOpen || autoClosingTimerId !== 0) return;

    setAutoClosingTimerId(
      setTimeout(() => {
        handleClose();
      }, autoCloseDelay)
    );
  }, [autoCloseDelay, isAutoClosing, isOpen, autoClosingTimerId, handleClose]);

  const prevDisplay = usePrevious(display);

  useEffect(() => {
    if (display === prevDisplay) return;

    if (!display) {
      handleClose();

      return;
    }

    setIsOpen(true);
  }, [display, handleClose, prevDisplay]);

  return (
    <Snackbar
      open={isOpen}
      onClose={handleClose}
      sx={{ minWidth: '400px' }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        sx={{ width: '100%' }}
        severity={
          type === 'notice' ? 'success' : type === 'warning' ? 'warning' : 'error'
        }
      >
        <AlertTitle>{heading}</AlertTitle>
        {children}
      </Alert>
    </Snackbar>
  );
}

NotificationContainer.defaultProps = {
  heading: null,
  className: null,
  closeCallback: null,
  autoCloseDelay: 5000,
  type: 'notice',
  isAutoClosing: false,
};

NotificationContainer.propTypes = {
  display: PropTypes.bool.isRequired,
  heading: PropTypes.string,
  className: PropTypes.string,
  closeCallback: PropTypes.func,
  autoCloseDelay: PropTypes.number,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  isAutoClosing: PropTypes.bool,
};

export default NotificationContainer;
