import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import MainDataContext from './main-data-context';
import { getMainData } from '../store';
import Reduce from '../reducer';

const MainDataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reduce, getMainData());

  return (
    <MainDataContext.Provider value={[state, dispatch]}>
      {children}
    </MainDataContext.Provider>
  );
};

MainDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainDataProvider;
