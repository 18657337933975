import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from '@rjsf/material-ui/dist/v5';
import spinnerReducers from '../../store/spinner/spinner-reducers';
import './editor.scss';
import widgets from './widgets';
import fields from './fields';
import { spinnerContext } from '../../store/spinner';
import { Box, Button } from '@mui/material';

import { cloneDeep } from 'lodash';
import { useCompactView } from '../hooks';
function Editor({
  className,
  uiSchema,
  formContext,
  rowChange,
  initialData,
  selectedSchema,
  handleSubmitForm,
  dataKey,
}) {
  const spinnerDispatch = useContext(spinnerContext)[1];
  const { compactView } = useCompactView();

  const handleSubmit = ({ formData }) => {
    handleSubmitForm(formData, rowChange);
  };

  return (
    <Form
      className={`${className} ${compactView && 'editor--compact'}  `}
      schema={selectedSchema}
      formData={initialData}
      onSubmit={handleSubmit}
      uiSchema={uiSchema}
      formContext={formContext}
      widgets={widgets}
      fields={fields}
      showErrorList
    >
      <Button variant="contained" type="submit" sx={{ zIndex: '1300!important' }}>
        Submit
      </Button>
    </Form>
  );
}

Editor.defaultProps = {
  className: null,
  uiSchema: null,
  rowChange: null,
  initialData: {},
  selectedSchema: {},
  handleSubmitForm: null,
  dataKey: null,
};

Editor.propTypes = {
  handleSubmitForm: PropTypes.func,
  rowChange: PropTypes.object,
  selectedSchema: PropTypes.object,
  initialData: PropTypes.object,
  className: PropTypes.string,
  uiSchema: PropTypes.object,
  formContext: PropTypes.object.isRequired,
  dataKey: PropTypes.string,
};
export default Editor;
