import { init as initApm } from '@elastic/apm-rum';

function startApm(config) {
  const apm = initApm({
    serviceName: 'Booking Engine Admin',
    serviceVersion: '1.0',
    serverUrl: config.APM_SERVER_URL,
    environment: config.ENVIRONMENT,
  });
}

export default startApm;
